import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className={'py-3 bg-dark'}>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <h5>More about...</h5>
            <ul>
              <li>
                <a href={'https://cimpress-support.atlassian.net/wiki/spaces/Content/pages/1053558596/Design+transformation'} target="_blank" rel="noopener noreferrer">Design
                  Transformation</a>
              </li>
              <li>
                <a href={'https://vistaprint.atlassian.net/wiki/spaces/MT/pages/44990664/Cross-sell+Page'} target="_blank" rel="noopener noreferrer">Cross
                  Sell</a>
              </li>
              <li>
                <a href={'https://cimpress-support.atlassian.net/wiki/spaces/Content/pages/1053561992/Design+Transformation+Realization'} target="_blank"
                  rel="noopener noreferrer">Realization</a>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} lg={8} xl={9}>
            <h5>Questions?</h5>
            <ul>
              <li>
                <h6>Mercury Squad</h6>
                <div>
                  <a title={'Send e-mail'} href={'mailto:mercurysquaddev@cimpress.com'}>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth={true} /> MercurySquadDev@cimpress.com
                  </a>
                </div>
                <div>
                  <a title={'Slack us'} href={'https://cimpress.slack.com/archives/CDVP74ABC'}>
                    <FontAwesomeIcon icon={faSlack} fixedWidth={true} /> squad-mercury
                  </a>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <h6>Pulsar Squad</h6>
                <div>
                  <a title={'Send e-mail'} href={'mailto:PulsarSquad@cimpress.com'}>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth={true} /> PulsarSquad@cimpress.com
                  </a>
                </div>
                <div>
                  <a title={'Slack us'} href={'https://cimpress.slack.com/archives/CB4LG5Z6V'}>
                    <FontAwesomeIcon icon={faSlack} fixedWidth={true} /> squad-pulsar
                  </a>
                </div>
              </li>
            </ul>

          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
